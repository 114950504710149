<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <v-card
          class="px-6 pt-4"
          style="margin-top: 14px; border-radius: 16px"
          outlined
        >
          <v-card-title
            class="pa-0 pb-2"
            style="text-shadow: grey 0.1em 0.1em 0.2em;"
          >
            Dados
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="nome"
                label="Descrição*"
                :rules="[rules.required, rules.maxLength(50)]"
                maxlength="50"
                required
                validate-on-blur
                dense
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="estados"
                :items="estado"
                item-text="nome"
                item-value="id"
                multiple
                small-chips
                clearable
                color="blue-grey lighten-2"
                :rules="[rules.minLength(1, '* Obrigatório')]"
                label="Estados*"
                autocomplete="nope"
                dense
                outlined
                rounded
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-if="data.index < 4"
                    rounded
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.nome }}
                  </v-chip>
                  <span
                    v-if="data.index === 4"
                    class="grey--text caption"
                  >
                    (+{{ estados.length - 4 }} outros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="mr-3"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/regionais` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            {{ isEditing ? 'Salvar' : 'Cadastrar' }}
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import RegionalStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/regional'
  import { mapState, mapActions } from 'vuex'
  export default {
    data () {
      return {
        valid: false,
        rules: rules,
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/regional', ['loading', 'isEditing']),
      ...mapFields('form', [
        'data.nome',
        'data.estados',
      ],
      ),
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    asyncComputed: {
      estado: {
        get () {
          const retorno = this.api.estados(1).then(estado => estado)
          return retorno
        },
        default: [],
      },
    },
    created () {
      if (!this.$store.state.form.regional) { this.$store.registerModule(['form', 'regional'], RegionalStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/regional', [BOOTSTRAP_PROFILE, SUBMIT]),
      remove (item) {
        const index = this.estados.indexOf(item.id)
        if (index >= 0) this.estados.splice(index, 1)
      },
      submitForm () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
